import {lazy} from 'react';
import {ExpenseDAMasterConetent} from '@app/screens/master-data-management/pages/expense-da-master';
import {Expense} from '@app/screens/module-specific-utilities/pages/expense';
import {ExpenseTAMaster} from '@app/screens/master-data-management/pages/expense-ta-master';
import {ComponentManagement} from '@app/screens/component-management';
import {ExpenseType} from '@app/screens/component-management/pages/expense-type';
import {Expenses} from '@app/screens/configuration-management/pages/expenses';
import {ConfigurationManagement} from '@app/screens/configuration-management';
import {ReverseExpense} from '@app/screens/module-specific-utilities/pages/status-manager';
import {BillLibrary} from '@app/screens/module-specific-utilities/pages/bill-library';
import {GenerateReport} from '@app/screens/module-specific-utilities/pages/generateReport';
import {ExpenseDate} from '@app/screens/configuration-management/pages/expenseDate';
import {ProRataMiscExpense} from '@app/screens/configuration-management/pages/pro-Rata-Misc-Expense';
import {ExpenseFileNumberGenerate} from '@app/screens/module-specific-utilities/pages/Expense File Number';
import {ExpenseReports} from '@app/screens/module-specific-utilities/pages/expense-reports';

const Home = lazy(() =>
  import('@app/screens/home').then(module => ({default: module.Home})),
);
const Auth = lazy(() =>
  import('@app/screens/generic/Auth').then(module => ({
    default: module.Auth,
  })),
);
const Login = lazy(() =>
  import('@app/screens/generic/Login').then(module => ({
    default: module.Login,
  })),
);
const AccessManagement = lazy(() =>
  import('@app/screens/access-management').then(module => ({
    default: module.AccessManagement,
  })),
);
const UserManagement = lazy(() =>
  import('@app/screens/access-management/pages/user-management').then(
    module => ({default: module.UserManagement}),
  ),
);
const AccessGroupMaster = lazy(() =>
  import('@app/screens/access-management/pages/access-group-master').then(
    module => ({default: module.AccessGroupMaster}),
  ),
);
const ModuleSpecifiUtilities = lazy(() =>
  import('@app/screens/module-specific-utilities').then(module => ({
    default: module.ModuleSpecifiUtilities,
  })),
);
const ApproveExpenses = lazy(() =>
  import('@app/screens/module-specific-utilities/pages/approve-expenses').then(
    module => ({default: module.ApproveExpenses}),
  ),
);
// const ExpenseFileNumberGenerate = lazy(() =>
//   import('@app/screens/module-specific-utilities/pages/expense-file-number').then(
//     module => ({default: module.ExpenseFileNumberGenerate}),
//   ),
// );

const PlanAndMeet = lazy(() =>
  import('@app/screens/module-specific-utilities/pages/plan-and-meet').then(
    module => ({default: module.PlanAndMeet}),
  ),
);
const DcrUnlockRequest = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/dcr-unlock-request'
  ).then(module => ({default: module.DcrUnlockRequest})),
);
const PositionManagement = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/position-management'
  ).then(module => ({default: module.PositionManagement})),
);
const ApprovalWorkflow = lazy(() =>
  import('@app/screens/configuration-management/pages/approval-workflow').then(
    module => ({default: module.ApprovalWorkflow}),
  ),
);
const ActivityMapping = lazy(() =>
  import('@app/screens/configuration-management/pages/activity-mapping').then(
    module => ({default: module.ActivityMapping}),
  ),
);
const GspSchedule = lazy(() =>
  import('@app/screens/configuration-management/pages/gsp-schedule').then(
    module => ({default: module.GspSchedule}),
  ),
);
const Unauthorized = lazy(() =>
  import('@app/screens/unauthorized').then(module => ({
    default: module.Unauthorized,
  })),
);
const InProgress = lazy(() =>
  import('@app/screens/in-progress').then(module => ({
    default: module.InProgress,
  })),
);
const InventoryManagement = lazy(() =>
  import('@app/screens/inventory-management').then(module => ({
    default: module.InventoryManagement,
  })),
);
const IntransitChallan = lazy(() =>
  import('@app/screens/inventory-management/pages/intransit-challan').then(
    module => ({default: module.IntransitChallan}),
  ),
);
const InTransitAccess = lazy(() =>
  import('@app/screens/inventory-management/pages/in-transit-access').then(
    module => ({default: module.InTransitAccess}),
  ),
);
const ExpectedUtilizationDate = lazy(() =>
  import(
    '@app/screens/inventory-management/pages/Expected Utilizaton Date'
  ).then(module => ({default: module.ExpectedUtilizationDate})),
);
const InventoryRules = lazy(() =>
  import('@app/screens/inventory-management/pages/inventoryRules').then(
    module => ({default: module.InventoryRules}),
  ),
);
const CnfReturnItems = lazy(() =>
  import('@app/screens/inventory-management/pages/cnf-return-items').then(
    module => ({default: module.CnfReturnItems}),
  ),
);
const PendencyReport = lazy(() =>
  import('@app/screens/inventory-management/pages/PendencyReport').then(
    module => ({default: module.PendencyReport}),
  ),
);
const LedgerReport = lazy(() =>
  import('@app/screens/inventory-management/pages/ledgerReport').then(
    module => ({default: module.LedgerReport}),
  ),
);

const ApprovedReturnedItems = lazy(() =>
  import('@app/screens/inventory-management/pages/ApprovedReturnedItems').then(
    module => ({default: module.ApprovedReturnedItems}),
  ),
);
const InventoryConfigurator = lazy(() =>
  import('@app/screens/inventory-management/pages/inventoryConfigurator').then(
    module => ({default: module.InventoryConfigurator}),
  ),
);
const ChallanShortQtyApproval = lazy(() =>
  import(
    '@app/screens/inventory-management/pages/ChallanShortQtyApproval'
  ).then(module => ({default: module.ChallonShortQtyApproval})),
);

const MasterDataManagement = lazy(() =>
  import('@app/screens/master-data-management').then(module => ({
    default: module.MasterDataManagement,
  })),
);

const DataCorrection = lazy(() =>
  import('@app/screens/master-data-management/pages/data-correction').then(
    module => ({default: module.DataCorrection}),
  ),
);

const HolidayManagement = lazy(() =>
  import('@app/screens/holiday-management').then(module => ({
    default: module.HolidayManagement,
  })),
);
const HolidayMaster = lazy(() =>
  import('@app/screens/holiday-management/pages/holiday-master').then(
    module => ({default: module.HolidayMaster}),
  ),
);
const StaffHolidayMaster = lazy(() =>
  import('@app/screens/holiday-management/pages/staff-holiday-master').then(
    module => ({default: module.StaffHolidayMaster}),
  ),
);
const StaffHolidayAllowed = lazy(() =>
  import('@app/screens/holiday-management/pages/staff-holiday-allowed').then(
    module => ({default: module.StaffHolidayAllowed}),
  ),
);
const LeaveManagement = lazy(() =>
  import('@app/screens/leave-management').then(module => ({
    default: module.LeaveManagement,
  })),
);
const OnboardNewDivision = lazy(() =>
  import('@app/screens/configuration-management/pages/onboard-new-division').then(
    module => ({default: module.OnboardNewDivision}),
  ),
);
const ReceivedReturnedItems = lazy(() =>
  import('@app/screens/inventory-management/pages/receivedReturnedItems').then(
    module => ({default: module.ReceivedReturnedItems}),
  ),
);

export const RoutesName: any = {
  Login: {
    path: '/login',
    componentName: 'Login',
  },
  Auth: {
    path: '/auth',
    componentName: 'Auth',
  },
  Home: {
    path: '/',
    componentName: 'Home',
  },
  _Home: {
    path: '/home',
    componentName: 'Home',
  },
  Unauthorized: {
    path: '/unauthorized',
    componentName: 'Unauthorized',
  },
  InProgress: {
    path: '/in-progress',
    componentName: 'InProgress',
  },
};

export const RoutesNameComponentMap: any = {
  _Home: <Home />,
  Home: <Home />,
  Login: <Login />,
  Auth: <Auth />,

  AccessManagement: <AccessManagement />,
  UserManagement: <UserManagement />,
  MasterDataManagement: <MasterDataManagement />,
  ExpenseDAMaster: <ExpenseDAMasterConetent />,
  BillLibrary: <BillLibrary />,
  AccessGroupMaster: <AccessGroupMaster />,
  ExpenseTAMaster: <ExpenseTAMaster />,
  ComponentManagement: <ComponentManagement />,
  Expenses: <Expenses />,
  ProRataMiscExpense: <ProRataMiscExpense />,
  ExpenseType: <ExpenseType />,
  ConfigurationManagement: <ConfigurationManagement />,
  Expense: <Expense />,
  Unauthorized: <Unauthorized />,
  ChallanShortQtyApproval: <ChallanShortQtyApproval />,
  ApprovedReturnedItems: <ApprovedReturnedItems />,
  InProgress: <InProgress />,
  PlanAndMeet: <PlanAndMeet />,
  DCRUnlockRequest: <DcrUnlockRequest />,
  PositionManagement: <PositionManagement />,
  ApprovalWorkflow: <ApprovalWorkflow />,
  InventoryManagement: <InventoryManagement />,
  IntransitChallan: <IntransitChallan />,
  InTransitAccess: <InTransitAccess />,
  CnfReturnItems: <CnfReturnItems />,
  InventoryConfigurator: <InventoryConfigurator />,
  ExpectedUtilizationDate: <ExpectedUtilizationDate />,
  InventoryRules: <InventoryRules />,
  ModuleSpecifiUtilities: <ModuleSpecifiUtilities />,
  ReverseExpense: <ReverseExpense />,
  GenerateReport: <GenerateReport />,
  ApproveExpenses: <ApproveExpenses />,
  ExpenseDate: <ExpenseDate />,
  ActivityMapping: <ActivityMapping />,
  GspSchedule: <GspSchedule />,
  DataCorrection: <DataCorrection />,
  ExpenseFileNumber: <ExpenseFileNumberGenerate />,
  ExpenseReports: <ExpenseReports />,
  PendencyReport: <PendencyReport />,
  LedgerReport: <LedgerReport />,
  HolidayManagement: <HolidayManagement />,
  HolidayMaster: <HolidayMaster />,
  StaffHolidayMaster: <StaffHolidayMaster />,
  StaffHolidayAllowed: <StaffHolidayAllowed />,
  LeaveManagement: <LeaveManagement />,
  OnboardNewDivision: <OnboardNewDivision />,
  ReceivedReturnedItems:<ReceivedReturnedItems/>,

};
