import Typography from '@mui/material/Typography';
import {Box, Button, FormControl, MenuItem, Select, Stack} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import {
  fetchCNFHeadquartersCreator,
  fetchCNFStatesAndDivisionsCreator,
  inventoryModuleCnfActions,
} from '@app/screens/inventory-management/pages/cnf-return-items/redux/slice';
import {
  fetchApproveReturnItemsFilterCreator,
  approvedReturnItemActions,
} from '@app/screens/inventory-management/pages/ApprovedReturnedItems/redux/slice';
import {adminCnfSelector} from '@app/screens/inventory-management/pages/cnf-return-items/redux/selectors';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {appSelector} from '@app/store/selectors';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  filterContainer: {
    width: '99%',
    height: 'auto',
    top: '55px',
    left: '8px',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '8px',
    borderRadius: '8px',
  },
  formControl: {
    margin: '8px',
  },
  icon: {
    marginTop: '4px',
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const ApproveUtility: any = {
  handleStateChange: null,
  handleHeadquarters: null,
  handleGoClick: null,
  handleDivisionChange: null,
};
export const ApprovedReturnedItemsNavBar = () => {
  const approveReturnStates = useSelector(adminCnfSelector.getCNFStates());
  const selectedApproveReturnDivision = useSelector(
    adminCnfSelector.getSelectedCnfDivision(),
  );
  const approveReturnHeadquarters = useSelector(
    adminCnfSelector.getCNFHeadquarters(),
  );
  const [approveReturnState, setApproveReturnState] = useState([]);
  const [approveReturnHq, setApproveReturnHq] = useState('');
  const [approveReturnDivision, setApproveReturnDivision] = useState<any>(
    selectedApproveReturnDivision,
  );
  const [enableData, setEnableData] = useState<any>(false);
  const selectedApproveReturnState = useSelector(
    adminCnfSelector.getSelectedCnfState(),
  );
  const writeAccess = useSelector(appSelector.getUserInfo());

  const [approveStateData, setApproveStateData] = useState([]);
  const [approveReturnDivisionData, setApproveReturnDivisionData] = useState(
    [],
  );
  const [approveReturnHqData, setApproveReturnHqData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCNFStatesAndDivisionsCreator());
    dispatch(approvedReturnItemActions.setSelectedApproveReturnItems([]));
    dispatch(inventoryModuleCnfActions.setSelectedCnfState([]));
    dispatch(inventoryModuleCnfActions.setSelectedCnfDivison([]));
    dispatch(inventoryModuleCnfActions.setSelectedCnfDivison([]));
    dispatch(approvedReturnItemActions.setInventoryAdminApprovePayload({}));

    return () => {
      dispatch(inventoryModuleCnfActions.setSelectedCnfState([]));
      dispatch(inventoryModuleCnfActions.setSelectedCnfDivison([]));
      dispatch(inventoryModuleCnfActions.setSelectedCnfDivison([]));
    };
  }, []);
  useEffect(() => {
    if (approveReturnStates?.stateData) {
      const States: any = [
        {id: 0, stateName: 'ALL'},
        ...approveReturnStates.stateData,
      ];
      setApproveStateData(States);
    }
  }, [approveReturnStates]);
  const handleStateChange = (e: any) => {
    const CNF: any = approveReturnStates?.divisionData
      ? [
          {id: 0, name: 'ALL', shortName: 'ALL'},
          ...approveReturnStates.divisionData,
        ]
      : [{id: 0, name: 'ALL', shortName: 'ALL'}];
    const StateID: any =
      e.target.value === 0
        ? approveStateData?.map((item: any) => item.id)
        : [e.target.value];
    setApproveReturnDivisionData(CNF);
    setEnableData(!enableData);
    dispatch(inventoryModuleCnfActions.setSelectedCnfState(StateID));
    setApproveReturnState(StateID);
    dispatch(approvedReturnItemActions.getArReqApiCalled(false));
  };
  const handleHeadquarters = (e: any) => {
    const hqID: any =
      e.target.value === 0
        ? approveReturnHqData?.map((item: any) => item.hqId)
        : [e.target.value];
    setApproveReturnHq(hqID);
    dispatch(approvedReturnItemActions.getArReqApiCalled(false));
  };
  const handleGoClick = () => {
    const req = {
      stateId: selectedApproveReturnState,
      divisionIds: selectedApproveReturnDivision,
      hQsId: approveReturnHq,
    };
    dispatch(fetchApproveReturnItemsFilterCreator(req));
    dispatch(approvedReturnItemActions.setInventoryAdminApprovePayload(req));
  };
  const handleDivisionChange = (e: any) => {
    const divID =
      e.target.value === 0
        ? approveReturnDivisionData?.map((item: any) => item.id)
        : [e.target.value];
    setApproveReturnDivision(divID);
    setEnableData(true);
    dispatch(inventoryModuleCnfActions.setSelectedCnfDivison(divID));
    const reqPayload = {
      stateId: [...approveReturnState],
      divisionId: [...divID],
    };
    dispatch(fetchCNFHeadquartersCreator(reqPayload));
    dispatch(approvedReturnItemActions.getArReqApiCalled(false));
  };
  useEffect(() => {
    if (!enableData) {
      setApproveReturnDivision('');
    }
    setApproveReturnHqData([]);
    setApproveReturnHq('');
  }, [approveReturnDivision, enableData]);

  useEffect(() => {
    const CNFHq: any = [
      {hqId: 0, hqNames: 'ALL'},
      ...approveReturnHeadquarters,
    ];
    setApproveReturnHqData(CNFHq);
  }, [approveReturnHeadquarters, approveReturnHq]);
  const enableButton =
    selectedApproveReturnState === '' ||
    approveReturnDivision === '' ||
    approveReturnHq === '';

  ApproveUtility.handleStateChange = handleStateChange;
  ApproveUtility.handleHeadquarters = handleHeadquarters;
  ApproveUtility.handleGoClick = handleGoClick;
  ApproveUtility.handleDivisionChange = handleDivisionChange;
  
  return (
    <Box sx={styles.container}>
      <Stack spacing={2} direction="row" marginLeft={'15px'}>
        <Typography
          data-testid={'cnf return request approval'}
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}>
          Approved Returned Items
        </Typography>
      </Stack>
      <Box sx={styles.filterContainer}>
        <FormControl sx={styles.formControl} fullWidth>
          <InputLabel id="select-state-label">Select State</InputLabel>
          <Select
            sx={{backgroundColor: '#ffffff'}}
            inputProps={{
              id: 'selectState-dropdown',
              'data-testid': 'selectState-dropdown',
            }}
            disableUnderline
            variant="outlined"
            labelId="select-state-label"
            onChange={handleStateChange}
            disabled={!writeAccess?.isWriteAccess}
            value={
              selectedApproveReturnState &&
              selectedApproveReturnState.length > 1
                ? 0
                : selectedApproveReturnState[0]
            }
            MenuProps={MenuProps}
            label="Select State">
            {approveStateData &&
              approveStateData != undefined &&
              approveStateData.length > 0 &&
              approveStateData.map((stateData: any) => {
                return (
                  <MenuItem value={stateData.id} key={stateData.value}>
                    {stateData.stateName}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl sx={styles.formControl} fullWidth>
          <InputLabel id="select-division-label">Select Division</InputLabel>
          <Select
            inputProps={{
              'data-testid': 'selectDivision-dropdown',
            }}
            sx={{backgroundColor: '#ffffff'}}
            variant="outlined"
            labelId="select-division-label"
            label="Select Division"
            disabled={!writeAccess?.isWriteAccess}
            onChange={(e: any) => handleDivisionChange(e)}
            MenuProps={MenuProps}
            value={
              approveReturnDivision !== '' && approveReturnDivision.length > 1
                ? 0
                : Number(approveReturnDivision[0]) || ''
            }>
            {approveReturnStates &&
              approveReturnDivisionData != undefined &&
              approveReturnDivisionData.length > 0 &&
              approveReturnDivisionData?.map((divisionData: any) => {
                return (
                  <MenuItem value={divisionData.id} key={divisionData.value}>
                    {divisionData.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl sx={styles.formControl} fullWidth>
          <InputLabel id="select-hq-label">Select HeadQuarters</InputLabel>
          <Select
            sx={{backgroundColor: '#ffffff'}}
            inputProps={{
              'data-testid': 'selectHeadQuarters-dropdown',
            }}
            disableUnderline
            variant="outlined"
            labelId="select-hq-label"
            label="Select HeadQuarters"
            MenuProps={MenuProps}
            disabled={!writeAccess?.isWriteAccess}
            onChange={e => handleHeadquarters(e)}
            value={
              approveReturnHq !== '' && approveReturnHq.length > 1
                ? 0
                : Number(approveReturnHq[0]) || ''
            }>
            {approveReturnHqData &&
              approveReturnHqData.length > 0 &&
              approveReturnHqData.map((hqData: any) => {
                return (
                  <MenuItem value={hqData.hqId} key={hqData.hqId}>
                    {hqData.hqNames}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <Button
          data-testid={'go-button'}
          sx={styles.formControl}
          variant="contained"
          size="small"
          onClick={handleGoClick}
          disabled={!writeAccess?.isWriteAccess || enableButton}>
          Go
        </Button>
      </Box>
    </Box>
  );
};
